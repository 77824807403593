<template>
    <div >
        <!--        导航-->
      <rxNavBar title=""></rxNavBar>
        <div>
            <div class="pinkDoor">
                <img :src="img.doorImg" class="doorLockImg">
                <div class="doorText">智能门锁</div>
                    <img :src="img.batteryImg" class="batteryImg">
                    <div class="batteryText">{{doorData.batteryDegree != '暂无' ? doorData.batteryDegree + '%' : doorData.batteryDegree}}</div>
                    <img :src="img.addressImg" class="addressImg">
                    <div class="addressText">{{doorData.addressDetail}}</div>
            </div>
        </div>

        <div class="passwordDiv">
            <span class="door">共门锁</span>
            <span class="doordevide">|</span>
            <div class="passwordText">{{doorData.doorPassWord}}</div>
        </div>
        <div class="roomDiv" v-if="roomHouse_id">
            <span class="room">房间锁</span>
            <span class="roomdevide">|</span>
            <div class="roomText">{{doorData.roomNumber}}</div>
            <!--<div class="modifyDiv"   @click="changePwdShow = !changePwdShow" v-show="!changePwdShow">修改</div>-->
        </div>

      <van-popup v-model="changePwdShow" position="bottom" style="height: 5.87rem">
        <img :src="img.quotationmarksImg" class="changeImg">
        <div class="changeText">修改房间密码</div>
        <div class="pwdDiv">
          <div class="newPwdText">新密码</div>
          <span class="devide">|</span>
          <input class="pwdInput" v-model="doorData.roomNumber" >
        </div>
        <div class="saveButton" @click="onInput">保存</div>

      </van-popup>

    </div>
</template>
<script>
import {
  NavBar, NumberKeyboard, Popup, Toast
} from 'vant'
import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
import {getStaffId, globaluserId, responseUtil} from "../../../libs/rongxunUtil";
import {userGetPassword, updateRoomEquipmentPassword, getUserPassword, getDoolLockInfo} from "../../../getData/getData";
export default {
  components:{
    [NavBar .name]:NavBar,
    [NumberKeyboard.name]:NumberKeyboard,
    [Toast.name]:Toast,
    [Popup.name]: Popup,
    rxNavBar
  },
  data(){
    return{
      img:{
        doorImg:require("../../../assets/images/bigDoorLock.png"),
        batteryImg:require("../../../assets/images/battery.png"),
        addressImg:require("../../../assets/images/address.png"),
        quotationmarksImg:require("../../../assets/images/quotationmarks.png")
      },



      show:false,
      changePwdShow:false,
      modifyShow:true,

      bigDoorLock:"",
      battery:"",

      doorData:{
        batteryDegree:"暂无",
        addressDetail:"",
        doorPassWord: "暂无",
        roomNumber:"暂无"
      },
        setHouse_id:'',
        roomHouse_id:'',
        equipment_id:'',
        isFirst:false,
        uuid:''
    }
  },
  mounted() {
      this.setHouse_id = ''
      this.equipment_id = this.$route.query.equipment_id
      this.isFirst = false
      this.doorData.addressDetail = this.$route.query.houseAddress
      this.setHouse_id = this.$route.query.setHouse_id
      this.roomHouse_id = this.$route.query.roomHouse_id
      this.uuid = this.$route.query.uuid
      //获取房源密码
      this.getRoomPassword()
      this.roomHouse_id = this.$route.query.roomHouse_id
  },

  methods:{
    leftReturn(){
      this.$router.go(-1)
    },
      //获取房间房源密码
      getRoomPassword(){
          let that = this
          let initData = {}
          initData.user_id = globaluserId()
          initData.setHouse_id = that.setHouse_id
          initData.roomHouse_id = that.roomHouse_id
          initData.type = "5"
          initData.uuid = that.uuid
          initData.equipment_id = that.equipment_id
          getUserPassword(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                  that.roomHouse_id = response.data.data.roomHouse_id
                  console.log(response.data)
                  if(response.data.data.code == '0'){
                      that.doorData.doorPassWord = response.data.data.doorPassWord == undefined ||  response.data.data.doorPassWord == '' ? '暂无' : response.data.data.doorPassWord  //公区密码
                      that.doorData.batteryDegree = response.data.data.power  //电量
                      if(response.data.data.roomHouse_id && response.data.data.roomHouse_id != '-1'){
                          that.equipment_id = response.data.data.equipment_id
                          that.doorData.roomNumber = response.data.data.roomNumber == '' ? '暂无' : response.data.data.roomNumber
                      }
                  }else if(response.data.data.code == '-3'){
                      that.doorData.doorPassWord = response.data.data.doorPassWord == undefined ||  response.data.data.doorPassWord == '' ? '暂无' : response.data.data.doorPassWord  //公区密码
                      that.doorData.batteryDegree = '暂无'  //电量
                      that.doorData.roomNumber = response.data.data.roomNumber
                      responseUtil.alertErrMsg(that,response.data.data.msg)
                  }else if(response.data.data.code == '-2'){
                      that.doorData.doorPassWord = response.data.data.doorPassWord  == undefined ||  response.data.data.doorPassWord == '' ? '暂无' : response.data.data.doorPassWord  //公区密码
                      that.doorData.batteryDegree = '暂无'  //电量
                      that.doorData.roomNumber = '暂无'
                      responseUtil.alertErrMsg(that,response.data.data.msg)
                  }else if(response.data.data.code == '-1'){
                      that.doorData.doorPassWord = '暂无'  //公区密码
                      that.doorData.batteryDegree = '暂无'  //电量
                      that.doorData.roomNumber = '暂无'
                      responseUtil.alertErrMsg(that,response.data.data.msg)
                  }else{
                      responseUtil.alertErrMsg(that,'操作失败')
                  }

              })
          })
      },
      //修改房间密码
    onInput() {
        let that = this
        let initData = {}
        initData.user_id = globaluserId()
        initData.equipment_id = that.equipment_id
        initData.roomHouse_id = that.doorData.roomHouse_id
        initData.roomNumber = that.doorData.roomNumber
        initData.isFirst = that.isFirst
        updateRoomEquipmentPassword(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                //获取房间房源密码
                that.getRoomPassword()
            })
        })
    },
  }
}

</script>
<style scoped>
    .cover{
        background: #000;
        width: 100%;
        height: 218px;
        filter: alpha(opacity=30);
        opacity: 0.3;
        z-index: 2;
        position: absolute;
        top: -0.26667rem;
    }
    .saveButton{
        width: 345px;
        height: 45px;
        border-radius: 8px;
        margin:-33px 15px;
        background: linear-gradient(to right,rgba(255, 194, 116, 1),rgba(255, 93, 59, 1));
        font-size: 16px;
        text-align: center;
        font-family: PingFangSC-Semibold;
        color: white;
        line-height: 45px;
    }
    .pwdInput{
        outline-style: none ;
        border: 0px;
        position: relative;
        top:7px ;
        height: 21px;
        font-size: 18px;
    }
    .devide{
        color: rgba(239, 239, 239, 1);
        position: relative;
        top: 8px;
        left: -9px;
    }
    .newPwdText{
        float: left;
        width: 45px;
        height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        margin: 14px 15px;
    }
    .pwdDiv{
        width: 345px;
        height: 50px;
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        margin:57px 15px;
    }
    .changeText{
        float: left;
        margin: 17px -5px;
        width: 105px;
        height: 21px;
        color: rgba(51, 51, 51, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Semibold;
    }
    .changeImg{
        float: left;
        width: 18px;
        height: 15px;
        margin: 19px 16px;
    }
    .changePwd{
        /*background-color: rgba(255, 255, 255, 1);*/
        background-color: #fafafa;
        width: 100%;
        height: 204px;
        float: left;
        margin-top: -67px;
    }
    .modifyDiv{
        width: 24px;
        height: 17px;
        color: rgba(49, 145, 255, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
        text-decoration: underline;
        margin-left: 295px;
        padding-top: 16px;
    }
    .doorLockImg{
        /*position: relative;*/
        /*left: -7px;*/
        width: 66px;
        height: 89px;
        float: left;
    }
    .roomDiv{
        width: 345px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        height: 50.5px;
        margin-top: 2px;
        margin-left: 15px;
    }
    .passwordDiv{
        width: 345px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: rgba(255, 255, 255, 1);
        height: 50.5px;
        margin-top: 12px;
        margin-left: 15px;
    }
    .room{
        float: left;
        margin-left: 15px;
        width: 45px;
        height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        margin-top: 14px;
    }
    .roomdevide{
        float: left;
        width: 1px;
        height: 14px;
        font-size: 30px;
        color: rgba(239, 239, 239, 1);
        margin-top: 9px;
        margin-left: 14px;
    }
    .roomText{
        float: left;
        width: 51px;
        height: 20px;
        line-height: 50.5px;
        color: rgba(153, 153, 153, 1);
        font-size: 15px;
        text-align: left;
        font-family: Oswald-Regular;
        margin-left: 100px;
        margin-left: 0.66667rem
    }
    .door{
        float: left;
        margin-left: 15px;
        width: 45px;
        height: 21px;
        color: rgba(34, 34, 34, 1);
        font-size: 15px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        margin-top: 14px;
    }
    .doordevide{
        float: left;
        float: left;
        width: 1px;
        height: 14px;
        font-size: 30px;
        color: rgba(239, 239, 239, 1);
        margin-top: 9px;
        margin-left: 14px;
    }
    .passwordText{
        float: left;
        width: 51px;
        height: 50px;
        color: rgba(153, 153, 153, 1);
        font-size: 15px;
        text-align: left;
        line-height: 50.5px;
        font-family: Oswald-Regular;
        margin-left: 0.66667rem;
    }

    .pinkDoor{
        width: 345px;
        height: 89px;
        background: linear-gradient(to left,rgba(253, 58, 132, 1),rgba(255, 166, 141, 1));
        margin-left: 15px;
        border-radius: 8px;
    }
    .doorText{
        width: 100px;
        height: 36px;
        color: rgba(255, 255, 255, 1);
        font-size: 25px;
        text-align: left;
        font-family: PingFangSC-Semibold;
        float: left;
        margin-top: 16px;
        margin-left: 2px;
    }
    .batteryImg{
        float: right;
        margin-top: 23px;
        margin-right: 50px;
    }
    .batteryText{
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        text-align: left;
        font-family: PingFangSC-Regular;
        width: 29px;
        height: 18px;
        line-height: 15px;
        opacity: 0.6;
        float: right;
        margin-top: 23px;
        margin-right: -45px;
    }
    .addressImg{
        width: 14.67px;
        height: 14.67px;
        float: left;
        margin-top: 58px;
        margin-left: -97px;
    }
    .addressText{
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
        /*width: 230px;*/
        /*height: 18px;*/
        /*color: rgba(255, 255, 255, 1);*/
        /*font-size: 13px;*/
        /*text-align: left;*/
        /*font-family: PingFangSC-Regular;*/
        /*position: relative;*/
        /*top: -185px;*/
        /*left: 90px;*/
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        top: 145px;
        width: 235px;
        height: 18px;
        color: rgba(255, 255, 255, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
        float: left;
        margin-left: 6px;
        margin-top: 2px;

    }



</style>






